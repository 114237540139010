export default defineNuxtRouteMiddleware((to, from) => {
  // Only run this check if the path starts with '/checkout'
  if (to.path.startsWith('/checkout')) {
    // If there's no plan param, navigate to /not-found
    if (!to.query.plan) {
      console.log('Redirecting to /not-found because plan is missing.')
      return navigateTo('/not-found')
    }
  }
})
